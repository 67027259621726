import React, { Component } from 'react';
import styled from 'styled-components';
import autoComplete  from '../assets/javascript/autocomplete.js'
import Litepicker from  '../assets/javascript/litepicker.js'

//css 
import '../assets/styles/calender.css'
import '../assets/styles/autocomplete.css'

//icons
import cross from '../assets/icons/cross.png';
import background from '../assets/images/background.svg';

const Styles = styled.div`
  .search-form {
    min-height: 10rem;
    display: flex;
    overflow: hidden;
    background: var(--primary) url(${background}) center repeat;

    &.toggle-bg {
      background: var(--secondary) url(${background}) center repeat;
    }

    &.hero {
      border-radius: 0.313em;
      text-align: left;
      margin-bottom: 1rem;
      min-height: 0;
      background: transparent;

      .margin {
        padding: 1.25rem;
      }
    }
  }

  .hth-mobile {
    display: flex;

    .inner {
      width: calc(50% - 5px);

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  .copy-container {
    margin: 0 0 1.5rem;
    color: var(--black);

    &.white {
      color: var(--white);
    }

    &--margin {
      margin: 0 0 3.125em;
    }
  }

  .heading {
    font-weight: 700;
    font-size: 1.5rem;

    &.toggle-color {
      color: var(--primary);
    }
  }

  .tagline, .description {
    font-size: .875rem;
    margin: 1rem 0 0;
  }

  .margin {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
  }

  .form {
    margin: 0 auto;
    width: 100%;
  }

  .fcon {
    display: flex;
    flex-direction: column;
  }

  .drop {
    position: relative;
  }

  .inner {
    height: 3.5rem;
    line-height: 3.5rem;
    background: #fff;
    border-radius: 0.313em;
    margin: 0.625rem 0 0 0;
    width: 100%;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;
    position: relative;

    .date {
      cursor: pointer;
    }

    label {
      height: 1rem;
      font-size: 0.875rem;
      padding: .5rem 1rem 0;
      font-weight: 700;
      position: absolute;
      top: -1.313rem;
      pointer-events: none;
      color: #000;
    }

    input {
      line-height: 30px;
      border-radius: 15px;
      width: 100%;
      padding: 21px 16px 0;
      box-sizing: border-box;
      background: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }

    select {
      line-height: 30px;
      border-radius: 15px;
      width: 100%;
      padding: 21px 16px 0;
      box-sizing: border-box;
      background: transparent;
      border: none;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: #000;

      &:focus {
        outline: none;
      }
    }
  }

  .inner.region_box {
    margin: 0;

    input {
      padding: 1.313rem 2.813rem 0 1rem;
    }
  }

  .search_box {
    min-height: 3.5rem;

    .submit {
      height: 3.5rem;
      width: 100%;
      margin: 0;
      border: none;
      padding: 0;
      background: var(--secondary);
      color: #fff;
      font-weight: 700;
      cursor: pointer;
      border-radius: .313rem;

      &:hover,
      &:focus {
        background: var(--lightenButton);
        transition: .3s;
      }

      &.toggle-bg {
        background: var(--primary);

        &:hover, &:focus {
          background: var(--primary);
          opacity: 0.9;
          transition: .3s;
        }
      }
    }
  }

  ul {
    list-style: none;
  }

  .close {
    height: 1.063rem;
    width: 0.813rem;
    position: absolute;
    right: 1.563rem;
    bottom: 2rem;
    cursor: pointer;
    z-index: 10;
    color: #000;
  }

  .close.hide {
    display: none;
  }

  .cross {
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(${cross});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .search-button {
    border: none;
    background-color: var(--secondary);
    position: relative;
    height: 3.125rem;
    z-index: 1;
    border-radius: .313em;
    line-height: 3.125rem;
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--white);
    text-align: center;
    outline: 0;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    display: inline;
    width: calc(50% - 0.3125rem);
    margin: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background: var(--lightenButton)
    }
  }

  .hth-mobile-cal {
    height: 100%;
    position: relative;
    max-height: max-content;
    overflow: visible;
  }
    
  .bcom-box {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 0.625rem;
      
      label {
          color: #000;
          font-size: 12px;
          margin-left: 6px;
          cursor: pointer;
          user-select: none;
          padding-top: 3px;

          &.white {
              color: #fff;
          }
      }

      input {
          display: none;

          &:checked {
              + {
                  .checkbox {
                      svg {
                          display: block;
                      }

                      background: var(--secondary);
                  }
              }
          }
      }

      .checkbox {
          height: 18px;
          width: 18px;
          background: #eeeeee;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
              height: 12px;
              width: 12px;
              fill: var(--primary);
              display: none;
          }
      }
  }

  @media only screen and (min-width: 1024px) {
    .search-form {
      &.hero {
        background: var(--primary) url(${background}) center repeat;
      }
    }

    .margin {
      padding: 1.25rem 0;
    }

    .fcon {
      flex-direction: row;
    }

    .inner.region_box {
      input {
        padding: 21px 45px 0 16px;
      }
    }

    .inner {
      margin: 0 0 0 10px;
    }

    .hth-mobile {
      flex-direction: row;
      background: none;
      width: 100%;

      .inner {
        width: 50%;

        &:first-of-type {
          margin-right: 0;
        }
      }
    }

    .search-button {
      max-width: 11.875rem;
      width: 20%;
    }
  }
`;

class SearchForm extends Component {
    constructor (params) {
        super(params)
        this.checkInRef = React.createRef();
        this.checkOutRef = React.createRef();
        this.regionRef = React.createRef();
    }

    state = {
        nights: "",
        guests: "",
        region: "",
        date: "",
        checkout: "",
        sortTest: false,
        heading: "",
        description: "",
        datepicker: "",
        bcom: true,
        filters: {
            //property types
            apartment: !!this.props.apartmentFilter,
            cottage: !!this.props.cottageFilter,
            lodge: (this.props.lodgeFilter || process.env.GATSBY_APP_ENV === "LH") ? true : false,

            //filters
            beach: !!this.props.beachFilter,
            garden: !!this.props.gardenFilter,
            hot_tub: !!this.props.hottubFilter,
            wifi: !!this.props.wifiFilter,
            parking: !!this.props.parkingFilter,
            baby: !!this.props.babyFilter,
            pool: !!this.props.poolFilter,
            fire: !!this.props.fireFilter,
            pet_count: this.props.petFilter ? 1 : false,
            luxury: process.env.GATSBY_APP_ENV === "LC",

            //tag filters 
            tags: [
                this.props.luxuryFilter ? "luxury" : "",
                this.props.gamesFilter ? "games room" : "",
                this.props.enclosedFilter ? "enclosed garden" : "",
                this.props.pubFilter ? "pub" : "",
                this.props.secludedFilter ? "secluded" : "",
                this.props.accessibleFilter ? "accessible" : ""
            ]
        }
    };

    changeRegion = (event) => {
        this.setState({region: event.target.value});
    }

    changeGuests = (event) => {
        this.setState({guests: event.target.value});
    }

    emptyRegion = () => {
        this.setState({region: ""});
        this.regionRef.current.focus();
    }

    compareBCom = () => {
        this.setState({bcom: !this.state.bcom});
    }

    dataLayerPush = (outboundEvent, outbountURL) => {
        if (typeof window !== 'undefined'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({'event': outboundEvent,  'outbound-destination': outbountURL});
        }
    }

    ajaxRequest = (options, suCallback, erCallback) => {
        var xhr = new XMLHttpRequest(),
            status = typeof options.status !== "undefined" ? options.status : 200;
        xhr.open(options.type, options.url, true);
        xhr.setRequestHeader('Content-Type', options.header);
        if (typeof options.accept !== "undefined") {
            xhr.setRequestHeader('Accept', options.accept);
        }
        if (typeof options.auth !== "undefined") {
            xhr.setRequestHeader('Authorization', options.auth);
        }
        if (typeof options.custom !== "undefined") {
            xhr.setRequestHeader(options.custom[0], options.custom[1]);
        }
        xhr.setRequestHeader('X-CSRF-Token', options.csrf);
        xhr.setRequestHeader('X-Requested-With', "XMLHttpRequest");

        xhr.timeout = 3000;
        xhr.ontimeout = function() {
            if (typeof erCallback !== 'undefined') {
                erCallback({ error: 'Request timed out' });
            }
        };

        xhr.onreadystatechange = function () {
            var response;
            if (xhr.readyState === 4 && xhr.status === status) {
                response = options.dataType === "json" ? JSON.parse(xhr.responseText) : xhr.responseText;
                if (typeof suCallback !== 'undefined') {
                    suCallback(response);
                }
            } else if (xhr.readyState === 4 && (xhr.status === 500 || xhr.status === 404 || xhr.status === 400)) {
                if (typeof erCallback !== 'undefined') {
                    erCallback(xhr);
                }
            }
        };
        
        xhr.send(options.data);
    }

    conductSearch = (event) => {
        event.preventDefault();

        let thisFriday = new Date();
        const day = thisFriday.getDay();
        const targetDay = 5;  // Friday
        let dayOffset = targetDay - day;
        if (dayOffset < 0) dayOffset += 7;

        thisFriday = new Date(thisFriday.getTime() + (dayOffset * 24 * 3600 * 1000));

        let thisMonday = new Date(thisFriday);
        let numberOfDayToAdd = 3;
        thisMonday.setDate(thisMonday.getDate() + numberOfDayToAdd );

        if (!this.state.nights) {
            if (!this.state.date){
                this.setState({date: String(thisFriday).split(' ').slice(0, 4).join(' ')});
                this.setState({checkout: String(thisMonday).split(' ').slice(0, 4).join(' ')});
            } else if (this.state.date) {
                let datePlusThreeNights = new Date(this.state.date);
                datePlusThreeNights.setDate(datePlusThreeNights.getDate() + 3);
                this.setState({checkout: String(datePlusThreeNights).split('00:00:00')[0]});
            }
            this.setState({nights: 3})
        }

        setTimeout(() => {
            this.dataLayerPush("outbound-search", "snaptrip search");

            if (this.state.bcom) {
                this.dataLayerPush("outbound-search", "booking.com search");
            }

            this.createUrl();
        }, 50);
    }

    createUrl = () => {
        let entryPage,
        exitPage;

        if (typeof window !== "undefined") {
            entryPage = window.location.origin + window.sessionStorage.getItem("landingUrl");
            exitPage = window.location.href;
        }

        let app = this.state;
        let filters = app.filters;
        let isPPC = this.props.ppc;
        let appUtm = (process.env.GATSBY_APP_ENV || "affiliate-site") ;

        switch(true) {
            case appUtm === "LWHT":
                appUtm = "lodges-with-hot-tubs"
              break;
            case appUtm === "LUXH":
                appUtm = "lodges-with-hot-tubs"
            break;
            case appUtm === "LUXH":
            appUtm = "luxury-hotels"
            break;
            case appUtm === "CH":
                appUtm = "cottage-holidays"
              break;
            case appUtm === "CS":
                appUtm = "coastal-stays"
              break;
            case appUtm === "VH":
                appUtm = "villa-holidays"
             break;
             case appUtm === "CW":
                appUtm = "cottage-weekend"
             break;
             case appUtm === "DFH":
                appUtm = "dog-friendly-hotels"
             break;
             case appUtm === "FVR":
                appUtm = "florida-vacation-rentals"
             break;
             case appUtm === "LH":
                appUtm = "lodge-holidays"
             break;
             case appUtm === "LC":
                appUtm = "luxury-cottages"
             break;
             case appUtm === "RC":
                appUtm = "remote-cottages"
             break;
             case appUtm === "ROMC":
                appUtm = "romantic-cottages"
             break;
             case appUtm === "UH":
                appUtm = "unique-holidays"
             break;
          }

        let utmParams = `&utm_source=${appUtm}&utm_medium=affiliate-site`;

        //remove blank values from tags array 
        let filteredTags = filters.tags.filter(function (el) {
            return el != "";
        });

        let url = `https://www.snaptrip.com/searches/custom?search[region]=${app.region}&search[check_in_on]=${app.date.replace(/ /g, '%20')}&search[minimum_nights]=${app.nights}&search[sleeps]=${app.guests}${ filteredTags.length ? '&search[tags]=' + filteredTags.join(',').replace(/ /g, '%20') : ""}`;

        //loop over filter obj
        for (const property in app.filters) {
            if (app.filters[property] && property !== "tags") {
              if (property === 'luxury' && appUtm === 'luxury-cottages') {
                continue;
              }
                url = url + `&search[${property}]=${app.filters[property]}`;
            }
        }

        //if ppc we want to add the gclid from google through to ST as a url param
        //we also want to add any utm params coming from google such as utm_term, utm_campaign etc.
        if (isPPC) {
            //gclid
            var gclid = location.href.includes("gclid=") ? "gclid=" + location.href.split("gclid=")[1]: "";
            gclid = gclid.includes("&") ? gclid.split('&')[0] : gclid;
            gclid ?  utmParams = utmParams + '&' + gclid : "";
            
            //msclkid
            var msclkid = location.href.includes("msclkid=") ? "msclkid=" + location.href.split("msclkid=")[1]: "";
            msclkid = msclkid.includes("&") ? msclkid.split('&')[0] : msclkid;
            msclkid ?  utmParams = utmParams + '&' + msclkid : "";
        }

        //utm_campaign
        let campaign = location.href.includes("utm_campaign=") ? "utm_campaign=" + location.href.split("utm_campaign=")[1]: "";
        campaign = campaign.includes("&") ? campaign.split('&')[0] : campaign;
        campaign ?  utmParams = utmParams + '&' + campaign : utmParams = utmParams + `&utm_campaign=Unlabelled-landing-${entryPage}-click-${exitPage}`;

        //utm_term
        let term = location.href.includes("utm_term=") ? "utm_term=" + location.href.split("utm_term=")[1]: "";
        term = term.includes("&") ? term.split('&')[0] : term;
        term ?  utmParams = utmParams + '&' + term : "";

        //sort test code asjusting the utm term
        if (!term && app.sortTest && process.env.GATSBY_APP_ENV === "LWHT" ) {
            utmParams = utmParams + '&utm_term=B';
            utmParams = utmParams + '&search[order]=property_ranking_t'
        } else if (!term && !app.sortTest &&process.env.GATSBY_APP_ENV === "LWHT" ) {
            utmParams = utmParams + '&utm_term=A';
        }

        //utm_content
        let content = location.href.includes("utm_content=") ? "utm_content=" + location.href.split("utm_content=")[1]: "";
        content = content.includes("&") ? content.split('&')[0] : content;
        content ?  utmParams = utmParams + '&' + content : "";

        this.openInNewTab(url + utmParams);
        
        let bcUrl;

        const label = process.env.GATSBY_APP_ENV === "ROMC" ? "RoC" : process.env.GATSBY_APP_ENV === "UB" ? "UKB" : process.env.GATSBY_APP_ENV;
        const bcaid = process.env.GATSBY_APP_ENV === "LWHT" ? "2242611" : "1908946";

        if (this.state.bcom && (["LWHT", "LC", "LH", "RC", "UH", "ROMC", "CW", "CS", "CH", "UB"].includes(process.env.GATSBY_APP_ENV))) {

            let checkIn = new Date(this.state.date);
            let formattedCheckIn = checkIn.toLocaleDateString('en-CA');
            let checkOut = new Date(this.state.checkout);
            let formattedCheckOut = checkOut.toLocaleDateString('en-CA');
            let filtersUrl = "";

            this.state.filters.apartment ? filtersUrl = filtersUrl + 'ht_id=201;' : null;
            this.state.filters.beach ? filtersUrl = filtersUrl + 'ht_beach=1;' : null;
            this.state.filters.cottage ? filtersUrl = filtersUrl + 'ht_id=220;' : null;
            this.state.filters.garden ? filtersUrl = filtersUrl + 'roomfacility=123' : null;
            this.state.filters.lodge ? filtersUrl = filtersUrl + 'ht_id=221;' : null;
            this.state.filters.parking ? filtersUrl = filtersUrl + 'hotelfacility=2;' : null;
            this.state.filters.pet_count ? filtersUrl = filtersUrl + 'hotelfacility=4;' : null;
            this.state.filters.pool ? filtersUrl = filtersUrl + 'hotelfacility=301;' : null;
            this.state.filters.wifi ? filtersUrl = filtersUrl + 'hotelfacility=107;' : null;
            this.state.filters.luxury ? filtersUrl = filtersUrl + 'class=4;class=5;' : null;

            filtersUrl.length > 0 ? filtersUrl = filtersUrl.replace(/=/g, '%3D').replace(/;/g, '%3B') : null;

            if (this.state.region && this.state.region.includes(",")) {
                const regions = ["England", "Scotland", "Wales"];
                for (let i = 0; i < regions.length; i++) {
                    if (this.state.region.includes(regions[i]) && !this.state.region.includes("United Kingdom")) {
                        this.state.region = this.state.region.replace(regions[i], "United Kingdom");
                        break;
                    }
                }
            }

            if (this.state.region) {
              let app = this;
              let regionParts = this.state.region.split(',')
              let cityName;
              let countryName;

              if (regionParts.length > 1) {
                cityName = regionParts[0].trim();
                countryName = regionParts[regionParts.length -1].trim()
              } else {
                cityName = regionParts[0].trim();

                  //when region is 'Scottish Highlands' etc
                  if (cityName.toLowerCase().includes('scottish')) {
                    cityName = cityName.replace(/Scottish\s*/i, '');
                }

                  // when region is 'All Destinations'
                  if (cityName.toLowerCase() === 'all destinations') {
                    cityName = "";
                  }
              }

              //get dest_Id
              this.ajaxRequest({
                      url: "https://www.laterooms.com/2.5/json/autocomplete?language=en&text=" + encodeURIComponent(cityName + (countryName ? ',' + countryName : "")),
                      type: "GET",
                      dataType: "json",
                  }, function(response) {
                      if (response.result.length > 0) {
                          // "222", "country" is fallback for "United Kingdom" search 
                          const destId = response.result[0].id || "222";
                          const destType = response.result[0].type || "country";
                          bcUrl = `https://www.booking.com/searchresults.en.html?dest_id=${destId}&dest_type=${destType}${app.state.date ? `&checkin=${formattedCheckIn}` : ""}${app.state.date ? `&checkout=${formattedCheckOut}`: ""}&do_availability_check=1&order=popularity${app.state.guests ? "&group_adults=" + app.state.guests : ""}&nflt=${label === "LWHT" ? "roomfacility%3D14%3B" : ""}${filtersUrl}&aid=${bcaid}&label=${label}-comparison-0000000-${gclid ? gclid : ''}`
                          app.openInSameTab(bcUrl)
                      } else {
                          let encodeRegion = region && encodeURIComponent(region);
                          bcUrl = encodeRegion ? `https://www.booking.com/searchresults.en.html?ss=${encodeRegion.replace(/%20/g, '+')}${app.state.date ? `&checkin=${formattedCheckIn}` : ""}${app.state.date ? `&checkout=${formattedCheckOut}`: ""}&do_availability_check=1&order=popularity${app.state.guests ? "&group_adults=" + app.state.guests : ""}&nflt=${label === "LWHT" ? "roomfacility%3D14%3B" : ""}${filtersUrl}&aid=${bcaid}&label=${label}-comparison-0000000-${gclid ? gclid : ''}` : `https://www.booking.com/?&aid=${bcaid}&label=${label}-comparison-0000000-${gclid ? gclid : ''}`;
                          app.openInSameTab(bcUrl)
                      }
                  }, function(error) {
                          let encodeRegion = region && encodeURIComponent(region);
                          bcUrl = encodeRegion ? `https://www.booking.com/searchresults.en.html?ss=${encodeRegion.replace(/%20/g, '+')}${app.state.date ? `&checkin=${formattedCheckIn}` : ""}${app.state.date ? `&checkout=${formattedCheckOut}`: ""}&do_availability_check=1&order=popularity${app.state.guests ? "&group_adults=" + app.state.guests : ""}&nflt=${label === "LWHT" ? "roomfacility%3D14%3B" : ""}${filtersUrl}&aid=${bcaid}&label=${label}-comparison-0000000-${gclid ? gclid : ''}` : `https://www.booking.com/?&aid=${bcaid}&label=${label}-comparison-0000000-${gclid ? gclid : ''}`;
                          app.openInSameTab(bcUrl)
                  }) 
          } else {
              let app = this;
              bcUrl = `https://www.booking.com/?&aid=${bcaid}&label=${label}-comparison-0000000-${gclid ? gclid : ''}${app.state.date ? `&checkin=${formattedCheckIn}` : ""}${app.state.date ? `&checkout=${formattedCheckOut}`: ""}`;
              app.openInSameTab(bcUrl)
          }
      }
    }

    openInSameTab = (url) => {
        window.open(url, '_self');
        window.location.href = url;
    }

    openInNewTab = (url) => {
        if (typeof window !== `undefined`) {
            let win = window.open('/redirect?url=' + url, '_blank');
            if (win) {
              win.focus();
          }
        }
    }

    checkForTest = () => {
        var app = this;
        if (typeof window !== "undefined") {
            //window.isTest = true;
            setTimeout(() => {
                if (window.isTest === true) {
                    app.setState({sortTest: true});
                }
            }, 100);
        }
    }

    // change the order of suggetsion lists
    sortSuggestionLists(response, term) {
        let matchedArray = [];
        let unmatchedArray = [];
        let newArray = [];
        let inputValue = term.toUpperCase();
        response.forEach(value => {
            let locationName = value.split(",")[0].toUpperCase();
            if (locationName.indexOf(inputValue) === 0) {
                matchedArray.push(value);
            } else {
                unmatchedArray.push(value);
            }
        });
        newArray = [...matchedArray, ...unmatchedArray];
        return newArray
    }

    componentDidMount () {
        let app = this; 
        app.checkForTest();

        app.setState({heading: app.props.heading});
        app.setState({description: app.props.description});

        // check for override then apply it to the region/dates/nights/guests respectively
        if (app.props.override) {
            app.setState({region: app.props.override});
        } 

        if (app.props.region) {
            app.setState({region: app.props.region});
        } 

        if (app.props.overrideDate) {
            var d = new Date(app.props.overrideDate);
            app.setState({date: d.toString().split('01:00:00')[0]});
        } 

        if (app.props.overrideNights) {
            app.setState({nights: app.props.overrideNights});
        } 

        if (app.props.overrideGuests) {
            app.setState({guests: app.props.overrideGuests});
        } 

        //check for location in order to build correctly on Server Side
        if (typeof location !== `undefined` && this.props.ppc) {
            var url = location.href;
            var title = url.includes("title=") ? location.href.split("title=")[1]: "";
            var description = url.includes("description=") ? location.href.split("description=")[1]: "";
            var region = url.includes("region=") ? location.href.split("region=")[1]: "";

            title = title.includes("&") ? title.split('&')[0] : title;
            description = description.includes("&") ? description.split('&')[0] : description;
            region = region.includes("&") ? region.split('&')[0] : region;

            title ? app.setState({heading: decodeURI(title)}): null;
            description ? app.setState({description: decodeURI(description)}): null;
            region ? app.setState({region: decodeURI(region)}): null;
        }

        if (typeof window !== `undefined`) {
            app.setState({datepicker: new Litepicker({
            element: app.checkInRef.current,
            elementEnd: app.checkOutRef.current,
            singleMode: false,
            autoRefresh: true,
            allowRepick: true,
            minDate: new Date() -1,
            numberOfColumns: 2,
            numberOfMonths: 2,
            minDays: 2,
            maxDays: 29,
            format: "D MMM YYYY", 
            tooltipText: {
              one: 'night',
              other: 'nights'
            },
            tooltipNumber: (totalDays) => {
              return totalDays - 1;
            },
            resetButton: () => {
                let btn = document.createElement('button');
                btn.innerText = 'Clear';
                btn.disabled = true;
                btn.setAttribute("id", 'clear-button');
                btn.setAttribute("class", "reset-button");

                btn.addEventListener('click', (evt) => {
                    evt.preventDefault();
                });

                return btn;
            },
            setup: (picker) => {
                picker.on('preselect', (date1, date2) => {
                    if (date1 && !date2) {
                        app.setState({date: date1.dateInstance.toString().split('00:00:00')[0]});
                        app.setState({checkout: ""});

                        document.getElementById('clear-button').disabled = false;
                    }
                });

                picker.on('selected', (date1, date2) => {
                        let timeDiff = Math.abs(date2.dateInstance.getTime() - date1.dateInstance.getTime());
                        let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

                        setTimeout(() => {
                            app.setState({date: date1.dateInstance.toString().split('00:00:00')[0]});
                            app.setState({nights: numberOfNights});
                            app.setState({checkout: date2.dateInstance.toString().split('00:00:00')[0]});
                        });
                });

                picker.on('show', (el) => {
                    document.documentElement.classList.add('cal-open');
                    //add close button for mobile
                    let cal = picker.ui;
                    cal.insertAdjacentHTML('afterend', '<button class="litepicker-close">Close</button>');

                    //if you've clicked on the check out field, then the check in should remain the same and you should begin by editing the check out
                    if (el.id !== 'checkout' && app.state.date) {
                        picker.clearSelection();
                    }
                });

                picker.on('hide', () => {
                    document.documentElement.classList.remove('cal-open');
                    document.querySelector('button.litepicker-close').remove();
                });
            }
          })
        });

        }

        new autoComplete({
            selector: app.regionRef.current,
            minChars: 3,
            cache: false,
            source: function (term, suggest) {
                app.ajaxRequest({
                    url: "https://www.snaptrip.com/searches/autocomplete?query=" + term,
                    type: "GET",
                    dataType: "json"
                }, function (response) {
                    let newResponse = app.sortSuggestionLists(response,term)
                    suggest(newResponse);
                });
            },
            renderItem: function(item, search) {
                search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
                if(item.includes(",")){
                    return '<div class="autocomplete-suggestion" data-val="' + item + '">' + item.replace(re, "<b>$1</b>").replace(",", "+").split("+")[0] + '<br /><span class="sub-region">' + item.replace(re, "<b>$1</b>").replace(",", "+").split("+")[1]  + '</span></div>';
                } else {
                    return '<div class="autocomplete-suggestion" data-val="' + item + '">' + item.replace(re, "<b>$1</b>") + '</div>';
                }
            },
            onSelect: function (e, term, item) {
                e.preventDefault();
                app.setState({region: term});
            }
        });
    }

    render() {
        let isHeading
        if (typeof document !== 'undefined') {
            isHeading = document.getElementsByTagName('h1').length > 0;
        }
        let showCopy = (this.props.heading || this.props.tagline || this.props.description);

        let guestsArr = [];
        for (let i = 1; i <= 10; i++) {
            guestsArr.push(i);
        }

        let appUtm = (process.env.GATSBY_APP_ENV || "affiliate-site") ;

        return (
            <Styles>
                <section className={`search-form ${this.props.hero ? "hero" : ""}${this.props.toggleColors ? "toggle-bg" : ""}`} id={ this.props.id ? this.props.id : null }>
                    <div className="container">
                    {!this.props.hero && <div className="col-1 hidden-m"/> }
                        <div className={` ${this.props.hero ? "" : "col-10"}`}>
                            <div className="margin">
                                { showCopy ? <div className={`copy-container ${this.props.color ? "white" : ""}`}>
                                        {this.props.heading && !isHeading && <h1 className={`heading ${this.props.toggleColors ? "toggle-color" : ""}`}>{this.state.heading ? this.state.heading : this.props.heading}</h1>}
                                        {this.props.heading && isHeading && <h2 className={`heading ${this.props.toggleColors ? "toggle-color" : ""}`}>{this.state.heading ? this.state.heading : this.props.heading}</h2>}
                                        {this.props.tagline && <p className="tagline">{this.state.tagline ? this.state.tagline : this.props.tagline}</p>}
                                        {this.props.description && <p className="description">{this.state.description ? this.state.description : this.props.description}</p>}
                                </div> : null}
                                <form className="form" id="search-form" onSubmit={this.conductSearch}>
                                    <div className="fcon">
                                        <div className="inner region_box">
                                            <label htmlFor="region">Destination</label>
                                            <input id="region" value={this.state.region} ref={this.regionRef} className="region" name="region" type="text" placeholder="Where are you travelling?" autoComplete="off" onChange={this.changeRegion}/>
                                            { this.state.region && <div className="ico close" onClick={this.emptyRegion}>✕</div> }
                                            <ul className="drop">
                                                <li id="autofill"/>
                                            </ul>
                                        </div>
                                        <div className="hth-mobile hth-mobile-cal">
                                            <div className="inner date_box">
                                                <label htmlFor="date">Check in*</label>
                                                <input id="date" ref={this.checkInRef} name="d8" placeholder="Add dates" readOnly value={this.state.date} type="text" className="date" autoComplete="off" />
                                            </div>
                                            <div className="inner date check-out">
                                                <label htmlFor="checkout">Check out*</label>
                                                <input className="date" ref={this.checkOutRef} name="checkout" readOnly value={this.state.checkout} type="text" autoComplete="off" id="checkout" placeholder="Add dates" />
                                            </div>
                                        </div>
                                        <div className="hth-mobile">
                                            <div className="inner guest_box">
                                                <label htmlFor="guests">Guests</label>
                                                <select name="guests" id="guests" className="date long" onChange={this.changeGuests} value={this.state.guests}>
                                                    <option className="disabled" value="" disabled >Add guests</option>
                                                    {guestsArr.map((index) => {
                                                        return <option value={index} key={index}>{index}+ Guest{ index > 1 ? 's' : ''}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="inner search_box">
                                                <button className={`submit ${this.props.toggleColors ? "toggle-bg" : ""}`}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {["LWHT", "LC", "LH", "RC", "UH", "ROMC", "CW", "CS", "CH", "UB"].includes(appUtm) &&                                    <div className="bcom-box" onClick={this.compareBCom}>
                                        <input id="lr" type="checkbox" name="lr" checked={this.state.bcom} readOnly/>
                                        <span className="checkbox">
                                            <svg viewBox="0 0 512 512">
                                                <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"></path>
                                            </svg>
                                        </span>
                                        <label htmlFor="hottub" className={` ${this.props.color ? "white" : ""}`}>
                                            Compare deals on Booking.com
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </Styles>
        )
    }
}

export default SearchForm;