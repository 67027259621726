import React, {Component, createRef} from 'react';
import Img from "gatsby-image";
import styled from "styled-components";
import SearchForm from "./SearchForm";
import defaultImgae from '../assets/images/The_Cotswolds.jpeg';

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @media only screen and (min-width: 1024px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 3;
  color: var(--white);
  text-align: ${props => props.alignText ? "left" : "center"};
  padding: ${props => props.searchForm ? "0 1.25rem 2rem" : "3rem 1.25rem"};
  display: flex;
  flex-direction: column;
  background: rgba(0,0,0,0.25); 

  .pad {
    padding: 0;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }

  @media only screen and (min-width: 1024px) {
    
    padding: 4rem 0 7.063rem;
    display: block;
  }
`;

const Title = styled.h1`
  padding: 4rem 0;
  margin: 0;
  order: 2;
  
  @media only screen and (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 3.063rem;
    padding:  ${props => props.searchForm ? "3.063rem 0 0" : "3.063rem 0"};
    margin: 0 auto;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  order: 3;

  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
  }
`;

const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const DefaultImage  = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
`;

const Overlay = styled.div`
  background: var(--codGrey);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const SearchFormContainer = styled.div`
  position: relative;
  z-index: 10;
  order: 1;
`;

class Hero extends Component {
  constructor (params) {
      super(params)
      this.formRef = createRef();
  }

  state = {
      heading: "",
      description: "",
  }

  componentDidMount () {
    let app = this;

    app.setState({heading: app.props.heading});
    app.setState({description: app.props.tagline});

    //check for location in order to build correctly on Server Side
    if (typeof location !== `undefined` && this.props.ppc) {
      var url = location.href;
      var title = url.includes("title=") ? location.href.split("title=")[1]: "";
      var description = url.includes("description=") ? location.href.split("description=")[1]: "";
      
      title = title.includes("&") ? title.split('&')[0] : title;
      description = description.includes("&") ? description.split('&')[0] : description;

      title ? app.setState({heading: decodeURI(title)}): null;
      description ? app.setState({description: decodeURI(description)}): null;
    }
  }

  render() {

    const {alignText} = this.props;
    const {searchForm} = this.props;

    return (
      <HeroSection id={ this.props.id ? this.props.id : null }>
        <Content alignText={alignText} searchForm={searchForm.length > 0}>
          <div className='pad container'>
            <div className="col-1 hidden-m"/>
                <div className="col-10">
                {(this.state.heading || this.props.heading) &&<Title>{this.state.heading ? this.state.heading : this.props.heading}</Title>}
                {searchForm.length > 0 && (
                  <SearchFormContainer>
                        <SearchForm {...this.props.searchForm[0]} hero ppc={this.props.ppc}/>
                    </SearchFormContainer>
                )}
                <Subtitle>{this.state.description ? this.state.description : this.props.description}</Subtitle>
            </div>
        </div>
        </Content>
        {this.props.image ? <Image fluid={this.props.image.asset.fluid} alt="" /> : <DefaultImage src={defaultImgae}  alt="" />}
        <Overlay />
      </HeroSection>
    );
  }
}

export default Hero;