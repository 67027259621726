import React  from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Section from "./Section";

//svgs
import chevron from '../assets/images/chevron.svg';

const Styles = styled.div`

    .link-block {
        margin-top: 3.125rem;
        padding: 0 1.25rem;
    }

    .container {
        display: inline-block;
    }

    .left {
        display: flex;
        flex-direction: column;

        .image-container {
            width: 100%;
            margin: 0 0 1rem;
        }
    }

    .outer-link {
        color: black;
        text-decoration: none;
    }

    .copy-container {

        & > div {

            *:last-child {
                margin: 0;
            }
        }

        h1, h2, h3, h4, h5, h6, p, ul, ol {
            margin: 0 0 1rem;
        }

        a {
            color: black;
        }

        ul, ol {
            padding-inline-start: 40px;
        }

        .link {
            margin: 1rem 0 0;
            display: inline-block;
            color: var(--primary);
            text-decoration: none;
            font-weight: 700;

            p {

            position: relative;
            padding-right: 1.6rem;

                span {
                    position: absolute;
                    right: 0;
                    height: 20px;
                    width: 20px;
                    background: var(--primary) url(${chevron}) center no-repeat;
                    border-radius: 100%;
                }

            }

        }
    }

    .image-container {

        margin: 1rem 0 0 0;

        .gatsby-image-wrapper {
            width: 100%!important;
            height: 22.625rem!important;
        }

        img {
            border-radius: .313rem;
        }
    }


    @media only screen and (min-width: 1024px) {
        .hidden-desk  {
            display: none!important;
        }

        .link-block {
            padding: 0;
        }

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
        }

        .link-container {
            display: flex;
            position: relative;
            background: var(--white);
            border-radius: .313rem;
            box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
            overflow: hidden;

            &.left {

                flex-direction: row;

                .image-container {
                    width: 55%;
                    margin: 0;
                }

                .copy-container {
                    order: 2;
                }
            }
        }

        .copy-container {
            width: 39%;
            height: fit-content;
            margin: auto 3%;
        }

        .image-container {
            width: 55%;
            min-height: 36.25rem;
            margin: 0;

            .gatsby-image-wrapper {
                height: 100%!important;
            }

            img {
                border-radius: 0;
            }
        }
    }
   
`;

const LinkBlock = (props) => {
    const portableTextContent = props._rawCopy;

    return (
        <Section id={ props.id ? props.id : null }>
            <Styles>
                <section className="link-block">
                    <div className="container">
                        <div className="col-1 hidden-m"/>
                        <div className="col-10">
                            <Link className="outer-link hidden-m" to={props.linkUrl}>
                                <div className={`link-container ${props.left ? "left" : ""}`}>
                                    <div className="copy-container">
                                        <BlockContent blocks={portableTextContent}/>
                                        {props.link ?
                                            <div className="link" to={props.linkUrl}>
                                                <p>{props.link}<span/></p>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="image-container">
                                        <Img fixed={props.image.asset.fixed}/>
                                    </div>
                                </div>
                            </Link>

                            <div className={`link-container hidden-desk  ${props.left ? "left" : ""}`}>
                                <div className="copy-container">
                                    <BlockContent blocks={portableTextContent}/>
                                    {props.link ?
                                        <Link className="link" to={props.linkUrl}>
                                            <p>{props.link}<span/></p>
                                        </Link>
                                        : null}
                                </div>
                                <div className="image-container">
                                    <Img fixed={props.image.asset.fixed}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Styles>
        </Section>
    );
};

export default LinkBlock;