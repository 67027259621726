import React from "react";
import styled from "styled-components";

const SectionBlock = styled.div`
  margin: 3.125rem 0;
`;

const Section = (props) => {
    return (
        <SectionBlock id={props.id ? props.id : null}>
            {props.children}
        </SectionBlock>
    );
};

export default Section;