import React from "react";
import Section from "./Section";
import styled from "styled-components";
import BlockContent from '@sanity/block-content-to-react';

const Container = styled.div`
  padding: 0 1.25rem;

  .container {
    display: inline-block;
  }

  @media only screen and (min-width: 1024px) {
    padding: 0;

    .container {
      margin-left: 50%;
      transform: translate(-50%);
    }
  }
`;

const Accordions = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
;`

const SingleAccordion = styled.div`
  border-bottom: 1px solid #e5e5e5;
  
  p {
    height: 0;
    opacity: 0;
    padding:  0 .75rem;
    transition: all .1s ease;
    pointer-events: none;
  }

  a {
    color: black;
  }
  
  svg {
    transition: all .15s ease;
    transform: rotate(180deg);
  }
  
  &.visible {
    p {
      opacity: 1;
      width: auto;
      height: auto;
      padding: .75rem;
      transition: all .1s ease;
      pointer-events: auto;

      @media only screen and (min-width: 1024px) {
        padding: 1rem .75rem;
      }
    }
    
    svg {
      transition: all .15s ease;
      transform: rotate(0deg);
    }
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  padding: .75rem;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-left: 2px solid var(--primary);
  
  h6 {
    font-size: .875rem;

    @media only screen and (min-width: 1024px) {
      font-size: 1rem;
    }
  }
  
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const Accordion = ({accordions}) => {
    const [accordionOpen, setAccordionOpen] = React.useState(null);
    const serializers = {
      marks: {
          link: ({mark, children}) => {
              const {href} = mark;
              return <a href={href} target="_blank" rel="noopener">{children}</a>
          },
      }
  };

    const toggleAccordion = (index) => {
        if (index === accordionOpen) {
            setAccordionOpen(null);
        } else {
            setAccordionOpen(index);
        }
    }

    return (
        <Section>
            <Container>
                <div className="container">
                    <div className="col-1 hidden-m"/>
                    <div className="col-10">
                        <Accordions>
                            {accordions.map((accordion, index) => {
                                return (
                                    <SingleAccordion key={index} className={accordionOpen === index ? "visible" : ""}>
                                       <AccordionHeader onClick={() => toggleAccordion(index)}>
                                           <h6>{accordion.title}</h6>
                                           <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                               <path d="M20 16L12 8L4 16" stroke="var(--primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                           </svg>
                                       </AccordionHeader>
                                       <BlockContent blocks={accordion._rawCopy} serializers={serializers}/>
                                    </SingleAccordion>
                                )
                            })}
                        </Accordions>
                    </div>
                </div>
            </Container>
        </Section>
    )
}

export default Accordion