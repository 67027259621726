import React from "react";

const TrustBox = () => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <div ref={ref} className="trustpilot-widget" data-businessunit-id="53977c11000064000578e82f" data-locale="en-GB" data-style-height="150px" data-style-width="100%" data-template-id="53aa8807dec7e10d38f59f32" data-theme="light">
            <a href="https://uk.trustpilot.com/review/snaptrip.com" target="_blank" rel="noopener"> Trustpilot</a>
        </div>
    );
};
export default TrustBox;