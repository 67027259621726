import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Section from "./Section";

const Styles = styled.div`

    .link-list {
        padding: 0 1.25rem;
    }

    .container {
        display: inline-block;
    }

    .title {
        margin: 0 0 1.25rem;
    }

    ul {
        list-style: none;
    }

    .link-item {
        cursor: pointer;
        transition: .3s;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        border: .0625rem solid var(--primary);
        border-radius: .3125rem;
        margin: 0 .325rem .325rem 0;
        background-color: var(--white);

        &:hover,
        &:focus-within {
            background: var(--primary);
            text-decoration: none;

            a {
                color: var(--white);
            }
        }
        
        a {
            padding: .75rem;
            text-decoration: none;
            color: var(--primary);
            font-size: .875rem;
            font-weight: 700;
            display: block;
        }
    }


    @media only screen and (min-width: 1024px) {

        .link-list {
            padding: 0;
        }

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
        }
    }

`;

const LinkList = (props) => {
    return (
        <Section id={ props.id ? props.id : null }>
            <Styles>
                <section className="link-list">
                    <div className="container">
                        <div className="col-1 hidden-m"/>
                        <div className="col-10">
                            {props.title ? <h2 className="title">{props.title}</h2> : null}
                            <ul className="list">
                                {props.linkItems.map((item, index) => {
                                    return (
                                        <li key={index} className="link-item">
                                            <Link to={item.link}>{item.copy}</Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </section>
            </Styles>
        </Section>
    );
};

export default LinkList;