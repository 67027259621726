import React from "react";
import styled from "styled-components";
import TrustBox from "./TrustBox";
import IconBlack from "../assets/icons/snaptrip-tp-black.svg";
import IconGuarantee from "../assets/icons/guarantee.svg";

const Background = styled.div`
  display: block;
  background: #EEEEEE;
  overflow: auto;
  padding: 1.5rem 0 1rem;

  .container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 1024px) {
    padding: 1rem 0;

    .block {
      display: flex;
      flex-direction: row;
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.563rem 1.25rem;
  margin: 0 auto;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    width: 33.33%;
    
    &.center {
      padding: 1.563rem 2rem;
    }
  }
`;

const Line = styled.div`
  display: block;
  width: 90%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  background: #9B9B9B;

  @media only screen and (min-width: 1024px) {
    width: 2px;
    height: 6.25em;
    background: #9B9B9B;
    right: -1px;
    margin-bottom: 0;
    bottom: unset;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    padding-bottom: 1.25rem;
  }
`;

const Copy = styled.div`
  margin: 1rem 0;
  color: var(--black);

  h3 {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
  }

  @media only screen and (min-width: 1024px) {
    text-align: center;
  }
`;

const Icon = styled.img`
  display: block;
  height: 5.125rem;
  width: 5.5rem;
  margin-top: 1rem;

  @media only screen and (min-width: 1024px) {
    margin: 0 1.5rem 0 0;
  }
`;

const GuaranteeBox = styled.div`
  font-size: .875rem;
  text-align: center;

  .copy {
    padding: 1rem;

    p {
      &:first-of-type {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  img {
    margin-top: 1rem;
    height: 3.75rem;
  }

  @media only screen and (min-width: 1024px) {
    img {
      margin-top: 1rem;
    }
  }
`;

const TrustPilot = () => {
    const App = process.env.GATSBY_APP_ENV || "LWHT";

    return (
        <Background>
            <section>
                <div className="container">
                    <div className="col-1 hidden-m"/>
                    <div className="col-10">
                        <div className="block">
                            <Box>
                                <TrustBox/>
                                <Line/>
                            </Box>
                            <Box className="center">
                                <Content>
                                    <a href={`https://www.snaptrip.com/?utm_source=${App}&utm_medium=affiliate-site`} target="_blank" rel="noreferrer">
                                        <Icon src={IconBlack} alt="Snaptrip"/>
                                    </a>
                                    <Copy>
                                        <h3>Trusted by our customers</h3>
                                        <p>As part of the Snaptrip family</p>
                                    </Copy>
                                </Content>
                                <Line/>
                            </Box>
                            <Box>
                                <GuaranteeBox>
                                    <img src={IconGuarantee} alt="Best price guarante"/>
                                    <div className="copy">
                                        <p>Best price guarantee</p>
                                        <p>No one can beat our prices.</p>
                                    </div>
                                </GuaranteeBox>
                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </Background>
    );
};
export default TrustPilot;